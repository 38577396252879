import { Languages } from '@lib/utils';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import { locales } from './locales';

export const i18n = i18next.createInstance();

i18n.use(initReactI18next).init({
  resources: locales,
  lng: 'it',
  returnEmptyString: false,
  fallbackLng: ['it'],
  interpolation: {
    escapeValue: false,
  },
});

export const changeLanguage = (language: Languages): void => {
  i18n.changeLanguage(language);
};

export * from './currency';
