import { DefaultOptions } from '@apollo/client';
import { createApolloClient, PersistedDataKeys } from '@lib/core';

import { persistedData } from 'common/helpers/persistedData';
import { refreshSession } from 'features/Auth';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  mutate: {
    fetchPolicy: 'no-cache',
  },
};

export const client = createApolloClient({
  defaultOptions,
  graphqlEndpoint: import.meta.env.VITE_API_URL as string,
  useErrorLink: true,
  getAccessToken: () => persistedData.get(PersistedDataKeys.AccessToken),
  getRefreshToken: () => persistedData.get(PersistedDataKeys.RefreshToken),
  getTokenExpire: () => persistedData.get(PersistedDataKeys.ExpirationTime),
  refreshSessionHandler: refreshToken => refreshSession(refreshToken),
});
