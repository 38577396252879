import { Portals } from '@lib/features-bll';
import { IMenuListItemConfig } from '@lib/react-components';

import { baseSidebarMenuItems, paidServicesSidebarMenuItems, sidebarMenuItemsConfig } from './sidebarMenu';

// TBD: Affiliations availability for portals
export const PORTAL_SIDEBAR_MENU_ITEMS_LIST: Record<Portals, IMenuListItemConfig[]> = {
  [Portals.OSPEDALESANRAFFAELE]: [
    sidebarMenuItemsConfig.dashboard,
    sidebarMenuItemsConfig.users,
    sidebarMenuItemsConfig.clinics,
    ...paidServicesSidebarMenuItems,
    sidebarMenuItemsConfig.invites,
    sidebarMenuItemsConfig.affiliations,
    sidebarMenuItemsConfig.admins,
    sidebarMenuItemsConfig.authorizations,
    sidebarMenuItemsConfig.report,
  ],
  [Portals.CAMPUSBIOMEDICO]: [
    sidebarMenuItemsConfig.dashboard,
    sidebarMenuItemsConfig.users,
    sidebarMenuItemsConfig.clinics,
    ...paidServicesSidebarMenuItems,
    sidebarMenuItemsConfig.invites,
    sidebarMenuItemsConfig.affiliations,
    sidebarMenuItemsConfig.admins,
    sidebarMenuItemsConfig.authorizations,
    sidebarMenuItemsConfig.report,
  ],
  [Portals.PUNTIRAF]: [
    sidebarMenuItemsConfig.dashboard,
    sidebarMenuItemsConfig.users,
    sidebarMenuItemsConfig.clinics,
    ...paidServicesSidebarMenuItems,
    sidebarMenuItemsConfig.invites,
    sidebarMenuItemsConfig.affiliations,
    sidebarMenuItemsConfig.admins,
    sidebarMenuItemsConfig.authorizations,
    sidebarMenuItemsConfig.report,
  ],
  [Portals.MPN]: baseSidebarMenuItems,
  [Portals.CML]: baseSidebarMenuItems,
  [Portals.BREAST]: baseSidebarMenuItems,
  [Portals.WELCARE]: [
    sidebarMenuItemsConfig.dashboard,
    sidebarMenuItemsConfig.institutions,
    sidebarMenuItemsConfig.users,
    sidebarMenuItemsConfig.invites,
    sidebarMenuItemsConfig.admins,
    sidebarMenuItemsConfig.authorizations,
    sidebarMenuItemsConfig.report,
  ],
  [Portals.MELANOMA]: baseSidebarMenuItems,
  [Portals.ASTICLINIC]: [
    sidebarMenuItemsConfig.dashboard,
    sidebarMenuItemsConfig.users,
    sidebarMenuItemsConfig.clinics,
    ...paidServicesSidebarMenuItems,
    sidebarMenuItemsConfig.invites,
    sidebarMenuItemsConfig.admins,
    sidebarMenuItemsConfig.authorizations,
    sidebarMenuItemsConfig.report,
  ],
  [Portals.POLIMEDICO]: [
    sidebarMenuItemsConfig.dashboard,
    sidebarMenuItemsConfig.users,
    sidebarMenuItemsConfig.clinics,
    ...paidServicesSidebarMenuItems,
    sidebarMenuItemsConfig.memorandum,
    sidebarMenuItemsConfig.invites,
    sidebarMenuItemsConfig.admins,
    sidebarMenuItemsConfig.authorizations,
    sidebarMenuItemsConfig.report,
  ],
  [Portals.DIGITALDIAB]: [
    sidebarMenuItemsConfig.dashboard,
    sidebarMenuItemsConfig.users,
    ...paidServicesSidebarMenuItems,
    sidebarMenuItemsConfig.invites,
    sidebarMenuItemsConfig.admins,
    sidebarMenuItemsConfig.authorizations,
    sidebarMenuItemsConfig.report,
  ],
  [Portals.MASTOCYTOSIS]: baseSidebarMenuItems,
  [Portals.WELCOMEDICINE]: [
    sidebarMenuItemsConfig.dashboard,
    sidebarMenuItemsConfig.users,
    ...paidServicesSidebarMenuItems,
    sidebarMenuItemsConfig.memorandum,
    sidebarMenuItemsConfig.invites,
    sidebarMenuItemsConfig.affiliations,
    sidebarMenuItemsConfig.admins,
    sidebarMenuItemsConfig.authorizations,
    sidebarMenuItemsConfig.report,
    sidebarMenuItemsConfig.announcements,
    sidebarMenuItemsConfig.modals,
  ],
  [Portals.BRAVOMED]: [
    sidebarMenuItemsConfig.dashboard,
    sidebarMenuItemsConfig.institutions,
    sidebarMenuItemsConfig.users,
    ...paidServicesSidebarMenuItems,
    sidebarMenuItemsConfig.invites,
    sidebarMenuItemsConfig.affiliations,
    sidebarMenuItemsConfig.admins,
    sidebarMenuItemsConfig.authorizations,
    sidebarMenuItemsConfig.report,
  ],
  [Portals.OSPEDALEGALEAZZISANTAMBROGIO]: [
    sidebarMenuItemsConfig.dashboard,
    sidebarMenuItemsConfig.users,
    sidebarMenuItemsConfig.clinics,
    ...paidServicesSidebarMenuItems,
    sidebarMenuItemsConfig.invites,
    sidebarMenuItemsConfig.affiliations,
    sidebarMenuItemsConfig.admins,
    sidebarMenuItemsConfig.authorizations,
    sidebarMenuItemsConfig.report,
  ],
  [Portals.SMARTCLINIC]: [
    sidebarMenuItemsConfig.dashboard,
    sidebarMenuItemsConfig.users,
    sidebarMenuItemsConfig.clinics,
    ...paidServicesSidebarMenuItems,
    sidebarMenuItemsConfig.invites,
    sidebarMenuItemsConfig.affiliations,
    sidebarMenuItemsConfig.admins,
    sidebarMenuItemsConfig.authorizations,
    sidebarMenuItemsConfig.report,
  ],
  [Portals.PALAZZODELLASALUTEWELLNESSCLINIC]: [
    sidebarMenuItemsConfig.dashboard,
    sidebarMenuItemsConfig.users,
    sidebarMenuItemsConfig.clinics,
    ...paidServicesSidebarMenuItems,
    sidebarMenuItemsConfig.invites,
    sidebarMenuItemsConfig.affiliations,
    sidebarMenuItemsConfig.admins,
    sidebarMenuItemsConfig.authorizations,
    sidebarMenuItemsConfig.report,
  ],
  [Portals.POLICLINICOSANDONATO]: [
    sidebarMenuItemsConfig.dashboard,
    sidebarMenuItemsConfig.users,
    sidebarMenuItemsConfig.clinics,
    ...paidServicesSidebarMenuItems,
    sidebarMenuItemsConfig.invites,
    sidebarMenuItemsConfig.affiliations,
    sidebarMenuItemsConfig.admins,
    sidebarMenuItemsConfig.authorizations,
    sidebarMenuItemsConfig.report,
  ],
  [Portals.POLICLINICOSANMARCO]: [
    sidebarMenuItemsConfig.dashboard,
    sidebarMenuItemsConfig.users,
    sidebarMenuItemsConfig.clinics,
    ...paidServicesSidebarMenuItems,
    sidebarMenuItemsConfig.invites,
    sidebarMenuItemsConfig.affiliations,
    sidebarMenuItemsConfig.admins,
    sidebarMenuItemsConfig.authorizations,
    sidebarMenuItemsConfig.report,
  ],
  [Portals.SMARTCLINICBERGAMO]: [
    sidebarMenuItemsConfig.dashboard,
    sidebarMenuItemsConfig.users,
    sidebarMenuItemsConfig.clinics,
    ...paidServicesSidebarMenuItems,
    sidebarMenuItemsConfig.invites,
    sidebarMenuItemsConfig.affiliations,
    sidebarMenuItemsConfig.admins,
    sidebarMenuItemsConfig.authorizations,
    sidebarMenuItemsConfig.report,
  ],
  [Portals.ISTITUTOCLINICOCITTADIBRESCIA]: [
    sidebarMenuItemsConfig.dashboard,
    sidebarMenuItemsConfig.users,
    sidebarMenuItemsConfig.clinics,
    ...paidServicesSidebarMenuItems,
    sidebarMenuItemsConfig.invites,
    sidebarMenuItemsConfig.affiliations,
    sidebarMenuItemsConfig.admins,
    sidebarMenuItemsConfig.authorizations,
    sidebarMenuItemsConfig.report,
  ],
  [Portals.ISTITUTOCLINICOSANROCCO]: [
    sidebarMenuItemsConfig.dashboard,
    sidebarMenuItemsConfig.users,
    sidebarMenuItemsConfig.clinics,
    ...paidServicesSidebarMenuItems,
    sidebarMenuItemsConfig.invites,
    sidebarMenuItemsConfig.affiliations,
    sidebarMenuItemsConfig.admins,
    sidebarMenuItemsConfig.authorizations,
    sidebarMenuItemsConfig.report,
  ],
  [Portals.ISTITUTOCLINICOSANTANNA]: [
    sidebarMenuItemsConfig.dashboard,
    sidebarMenuItemsConfig.users,
    sidebarMenuItemsConfig.clinics,
    ...paidServicesSidebarMenuItems,
    sidebarMenuItemsConfig.invites,
    sidebarMenuItemsConfig.affiliations,
    sidebarMenuItemsConfig.admins,
    sidebarMenuItemsConfig.authorizations,
    sidebarMenuItemsConfig.report,
  ],
  [Portals.ISTITUTOCLINICOVILLAAPRICA]: [
    sidebarMenuItemsConfig.dashboard,
    sidebarMenuItemsConfig.users,
    sidebarMenuItemsConfig.clinics,
    ...paidServicesSidebarMenuItems,
    sidebarMenuItemsConfig.invites,
    sidebarMenuItemsConfig.affiliations,
    sidebarMenuItemsConfig.admins,
    sidebarMenuItemsConfig.authorizations,
    sidebarMenuItemsConfig.report,
  ],
  [Portals.ISTITUTODICURACITTADIPAVIA]: [
    sidebarMenuItemsConfig.dashboard,
    sidebarMenuItemsConfig.users,
    sidebarMenuItemsConfig.clinics,
    ...paidServicesSidebarMenuItems,
    sidebarMenuItemsConfig.invites,
    sidebarMenuItemsConfig.affiliations,
    sidebarMenuItemsConfig.admins,
    sidebarMenuItemsConfig.authorizations,
    sidebarMenuItemsConfig.report,
  ],
  [Portals.ISTITUTOCLINICOBEATOMATTEO]: [
    sidebarMenuItemsConfig.dashboard,
    sidebarMenuItemsConfig.users,
    sidebarMenuItemsConfig.clinics,
    ...paidServicesSidebarMenuItems,
    sidebarMenuItemsConfig.invites,
    sidebarMenuItemsConfig.affiliations,
    sidebarMenuItemsConfig.admins,
    sidebarMenuItemsConfig.authorizations,
    sidebarMenuItemsConfig.report,
  ],
  [Portals.VILLAERBOSA]: [
    sidebarMenuItemsConfig.dashboard,
    sidebarMenuItemsConfig.users,
    sidebarMenuItemsConfig.clinics,
    ...paidServicesSidebarMenuItems,
    sidebarMenuItemsConfig.invites,
    sidebarMenuItemsConfig.affiliations,
    sidebarMenuItemsConfig.admins,
    sidebarMenuItemsConfig.authorizations,
    sidebarMenuItemsConfig.report,
  ],
  [Portals.CASADICURAVILLACHIARA]: [
    sidebarMenuItemsConfig.dashboard,
    sidebarMenuItemsConfig.users,
    sidebarMenuItemsConfig.clinics,
    ...paidServicesSidebarMenuItems,
    sidebarMenuItemsConfig.invites,
    sidebarMenuItemsConfig.affiliations,
    sidebarMenuItemsConfig.admins,
    sidebarMenuItemsConfig.authorizations,
    sidebarMenuItemsConfig.report,
  ],
  [Portals.ISTITUTICLINICIZUCCHI]: [
    sidebarMenuItemsConfig.dashboard,
    sidebarMenuItemsConfig.users,
    sidebarMenuItemsConfig.clinics,
    ...paidServicesSidebarMenuItems,
    sidebarMenuItemsConfig.invites,
    sidebarMenuItemsConfig.affiliations,
    sidebarMenuItemsConfig.admins,
    sidebarMenuItemsConfig.authorizations,
    sidebarMenuItemsConfig.report,
  ],
  [Portals.VIRTUALHOSPITAL]: baseSidebarMenuItems,
  [Portals.CURAREINRETE]: baseSidebarMenuItems,
  [Portals.SCN]: [
    sidebarMenuItemsConfig.dashboard,
    sidebarMenuItemsConfig.users,
    sidebarMenuItemsConfig.clinics,
    ...paidServicesSidebarMenuItems,
    sidebarMenuItemsConfig.invites,
    sidebarMenuItemsConfig.affiliations,
    sidebarMenuItemsConfig.admins,
    sidebarMenuItemsConfig.authorizations,
    sidebarMenuItemsConfig.report,
  ],
  [Portals.SANRAFFAELETERMINI]: [
    sidebarMenuItemsConfig.dashboard,
    sidebarMenuItemsConfig.users,
    sidebarMenuItemsConfig.clinics,
    ...paidServicesSidebarMenuItems,
    sidebarMenuItemsConfig.invites,
    sidebarMenuItemsConfig.affiliations,
    sidebarMenuItemsConfig.admins,
    sidebarMenuItemsConfig.authorizations,
    sidebarMenuItemsConfig.report,
  ],
  [Portals.SANRAFFAELE]: [
    sidebarMenuItemsConfig.dashboard,
    sidebarMenuItemsConfig.users,
    sidebarMenuItemsConfig.clinics,
    ...paidServicesSidebarMenuItems,
    sidebarMenuItemsConfig.invites,
    sidebarMenuItemsConfig.affiliations,
    sidebarMenuItemsConfig.admins,
    sidebarMenuItemsConfig.authorizations,
    sidebarMenuItemsConfig.report,
  ],
  [Portals.ANALIFE]: [
    sidebarMenuItemsConfig.dashboard,
    sidebarMenuItemsConfig.users,
    sidebarMenuItemsConfig.clinics,
    ...paidServicesSidebarMenuItems,
    sidebarMenuItemsConfig.invites,
    sidebarMenuItemsConfig.affiliations,
    sidebarMenuItemsConfig.admins,
    sidebarMenuItemsConfig.authorizations,
  ],
  [Portals.LIBEROMEDICAL]: [
    sidebarMenuItemsConfig.dashboard,
    sidebarMenuItemsConfig.users,
    sidebarMenuItemsConfig.clinics,
    ...paidServicesSidebarMenuItems,
    sidebarMenuItemsConfig.invites,
    sidebarMenuItemsConfig.affiliations,
    sidebarMenuItemsConfig.admins,
    sidebarMenuItemsConfig.authorizations,
  ],
  [Portals.CENTROMEDICO]: [
    sidebarMenuItemsConfig.dashboard,
    sidebarMenuItemsConfig.users,
    sidebarMenuItemsConfig.clinics,
    ...paidServicesSidebarMenuItems,
    sidebarMenuItemsConfig.invites,
    sidebarMenuItemsConfig.affiliations,
    sidebarMenuItemsConfig.admins,
    sidebarMenuItemsConfig.authorizations,
  ],
  [Portals.BLUMEDICAMODENA]: [
    sidebarMenuItemsConfig.dashboard,
    sidebarMenuItemsConfig.users,
    sidebarMenuItemsConfig.clinics,
    ...paidServicesSidebarMenuItems,
    sidebarMenuItemsConfig.invites,
    sidebarMenuItemsConfig.affiliations,
    sidebarMenuItemsConfig.admins,
    sidebarMenuItemsConfig.authorizations,
  ],
  [Portals.PROGETTOORA]: [
    sidebarMenuItemsConfig.dashboard,
    sidebarMenuItemsConfig.users,
    ...paidServicesSidebarMenuItems,
    sidebarMenuItemsConfig.invites,
    sidebarMenuItemsConfig.admins,
    sidebarMenuItemsConfig.authorizations,
  ],
  [Portals.TIBERIAHOSPITAL]: [
    sidebarMenuItemsConfig.dashboard,
    sidebarMenuItemsConfig.users,
    sidebarMenuItemsConfig.clinics,
    ...paidServicesSidebarMenuItems,
    sidebarMenuItemsConfig.invites,
    sidebarMenuItemsConfig.affiliations,
    sidebarMenuItemsConfig.admins,
    sidebarMenuItemsConfig.authorizations,
    sidebarMenuItemsConfig.report,
  ],
  [Portals.CDI]: [
    sidebarMenuItemsConfig.dashboard,
    sidebarMenuItemsConfig.users,
    sidebarMenuItemsConfig.clinics,
    ...paidServicesSidebarMenuItems,
    sidebarMenuItemsConfig.invites,
    sidebarMenuItemsConfig.affiliations,
    sidebarMenuItemsConfig.admins,
    sidebarMenuItemsConfig.authorizations,
    sidebarMenuItemsConfig.report,
  ],
  [Portals.MARIACECILIAHOSPITAL]: [
    sidebarMenuItemsConfig.dashboard,
    sidebarMenuItemsConfig.users,
    sidebarMenuItemsConfig.clinics,
    ...paidServicesSidebarMenuItems,
    sidebarMenuItemsConfig.invites,
    sidebarMenuItemsConfig.affiliations,
    sidebarMenuItemsConfig.admins,
    sidebarMenuItemsConfig.authorizations,
    sidebarMenuItemsConfig.report,
  ],
};
