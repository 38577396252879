export const ROUTE_PATHS = {
  // Authorized routes
  dashboard: '/dashboard',
  clinics: '/clinics',
  clinic: '/clinics/:clinicId',
  clinicEdit: '/clinics/:clinicId/edit',
  clinicCreate: '/clinics/create',
  institutions: '/institutions',
  institutionCreate: '/institutions/create',
  institutionEdit: '/institutions/:id/edit',
  users: '/users',
  userDetails: '/users/:id',
  deleteUser: '/users/delete/:id/:userName',
  memorandum: '/memorandum',
  requests: '/requests',
  requestDetails: '/requests/:id',
  report: '/report',
  invites: '/invites',
  inviteCreate: '/invites/create',
  inviteDetails: '/invites/:id',
  affiliations: '/affiliations',
  affiliationCreate: '/affiliations/create',
  affiliationDetails: '/affiliations/:id',
  admins: '/admins',
  adminCreate: '/admins/create',
  adminPreview: '/admins/:id',
  authorizations: '/authorizations',
  authorizationDetails: '/authorizations/:id',
  announcements: '/announcements',
  modals: '/modals',
  announcementCreate: '/announcements/create',
  announcementUpdate: '/announcements/update/:id',
  modalsCreate: '/modals/create',
  doctorImport: '/doctorImport',
  patientImport: '/patientImport',
  404: '/*',
};
