import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  header: {
    backgroundColor: theme.palette.blue.light,
    padding: '16px 24px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));
